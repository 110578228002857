import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../App.css';

const CameraCapture = () => {
    const [photo, setPhoto] = useState(null); // To store uploaded photo
    const [breed, setBreed] = useState('');
    const [accuracy, setAccuracy] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const [isMobile, setIsMobile] = useState(false);
    const [deferredPrompt, setDeferredPrompt] = useState(null);
    const [showInstallPrompt, setShowInstallPrompt] = useState(false);

    const fileInputRef = useRef(null);

    useEffect(() => {
        const checkMobile = () => {
            const isMobileDevice = /Mobi|Android/i.test(navigator.userAgent);
            setIsMobile(isMobileDevice);
        };

        checkMobile();

        
        const handleBeforeInstallPrompt = (e) => {
            e.preventDefault();
            setDeferredPrompt(e);
            setShowInstallPrompt(true);
        };

        window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);

        window.addEventListener('appinstalled', () => {
            setShowInstallPrompt(false);
        });

        return () => {
            window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
        };
    }, []);

    const handleInstallClick = () => {
        if (deferredPrompt) {
            deferredPrompt.prompt();
            deferredPrompt.userChoice.then((choiceResult) => {
                if (choiceResult.outcome === 'accepted') {
                    console.log('User accepted the A2HS prompt');
                } else {
                    console.log('User dismissed the A2HS prompt');
                }
                setDeferredPrompt(null);
                setShowInstallPrompt(false);
            });
        }
    };

    const handleFileUpload = (e) => {
        const file = e.target.files[0];
        if (file) {
            setPhoto(file); // Save the selected file
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!photo) {
            alert('Please upload a photo.');
            return;
        }

        // Set loading to true when upload starts
        setIsLoading(true);

        const formData = new FormData();
        formData.append('photo', photo); // Only sending photo to the backend

        try {
            // Send photo to backend for breed prediction
            const response = await axios.post('https://race.gravos.fr/api/upload_image', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });

            const { breed_info } = response.data;

            // Extract breedName and confidence (accuracy) from response data
            setBreed(breed_info.labelName); // Set breed name
            setAccuracy((breed_info.confidence * 100).toFixed(2)); // Convert confidence to percentage

            // Reset form state after successful upload
            setPhoto(null);
            if (fileInputRef.current) {
                fileInputRef.current.value = ''; // Clear file input
            }
        } catch (error) {
            console.error('Error uploading photo:', error.response?.data || error.message);
            alert('Failed to upload photo.');
        } finally {
            // Set loading to false once upload is complete
            setIsLoading(false);
        }
    };

    return (
        <div className="container mt-5" style={{ backgroundColor: '#51545D' }}>
            {isLoading && (
                <div className="loading-overlay">
                    <div className="spinner-border text-light" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>
                </div>
            )}

            {showInstallPrompt && (
                <div style={{
                    position: 'fixed',
                    top: '10px',
                    left: '10px',
                    textAlign: 'center',
                    color: 'yellow',
                    zIndex: 1000 // Ensure it stays above other elements
                }}>
                    <p>Mettre l'icone</p>
                    <img
                        src={`${process.env.PUBLIC_URL}/install_app.png`}
                        alt="Install App"
                        style={{
                            width: '40px',
                            height: 'auto',
                            cursor: 'pointer',
                        }}
                        onClick={handleInstallClick}
                    />
                </div>
            )}

            <h2 className="text-center">
                <img
                    src={`${process.env.PUBLIC_URL}/gravos_logo_page.png`}
                    alt="Logo"
                    className="img-fluid"
                    style={{ maxWidth: '200px' }}
                />
            </h2>

            <div className="text-center mt-4">
                {/* Display uploaded photo preview if available */}
                {photo && (
                    <div className="mt-3">
                        <img
                            src={URL.createObjectURL(photo)}
                            alt="Uploaded"
                            className="img-fluid"
                            style={{ maxWidth: '300px' }}
                        />
                    </div>
                )}
            </div>

            {/* Form to upload photo */}
            <form onSubmit={handleSubmit} className="mt-4">
                <div className="mb-3">
                    <label className="form-label" style={{ color: 'black' }}>Envoyer une photo:</label>
                    <input
                        type="file"
                        accept="image/*"
                        className="form-control"
                        onChange={handleFileUpload}
                        ref={fileInputRef}
                        required
                    />
                </div>
                <button type="submit" className="btn btn-custom w-100 mb-4 mt-2">
                    Envoyer
                </button>
            </form>

            {/* Display the breed and accuracy after receiving the response */}
            {breed && (
                <div className="text-center mt-4">
                    <h4 style={{ color: 'white' }}>Race: {breed}</h4>
                    <p style={{ color: 'white' }}>Certitude: {accuracy}%</p>
                </div>
            )}
        </div>
    );
};

export default CameraCapture;
